import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { BrandRoomsProps } from './interface';

const Component = lazy(() => import(/* webpackChunkName: "components/BrandRooms/" */'./BrandRooms'));

function BrandRooms(props: BrandRoomsProps) {
	return (
		<Suspense fallback={ <Spin /> }>
			<Component { ...props } />
		</Suspense>
	);
}

export default BrandRooms;
