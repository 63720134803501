import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';

const Component = lazy(() => import(/* webpackChunkName: "components/Header/" */'./Header'));

function Header() {
	return (
		<Suspense fallback={ <Spin /> }>
			<Component />
		</Suspense>
	);
}

export default Header;
