import React from 'react';
import { Layout } from 'antd';
import BrandRooms from './components/BrandRooms';
import Header from './components/Header';

import styles from './App.module.less';

function App() {
	return (
		<Layout>
			<Layout.Content className={ styles.content }>
				<Header />

				<div className={ styles.rooms }>
					{ process.env.CONFIG.brandIds.map((brand, i) => (
						<BrandRooms
							key={ i }
							brand={ brand }
						/>
					)) }
				</div>
			</Layout.Content>

			<Layout.Footer>
				{ process.env.CONFIG.name } &#169; { new Date().getFullYear() }. Alle rechten voorbehouden
			</Layout.Footer>
		</Layout>
	);
}

export default App;
